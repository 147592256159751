import {createApp} from 'vue'
import App from './App.vue'
// import 'remixicon/fonts/remixicon.css'
import axios from 'axios'
import {createRouter, createWebHashHistory} from "vue-router";
import {createStore} from 'vuex'
import mitt from 'mitt'
import ElementPlus from 'element-plus'
import {ElNotification} from 'element-plus'
// import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const emitter = mitt()

const app = createApp(App)

app.config.globalProperties.$emitter = emitter
app.config.globalProperties.$ElNotification = ElNotification
app.config.globalProperties.$axios = axios
axios.defaults.headers.post['Content-Type'] = 'application/json';


const Home = () => import('@/components/Home')
const Music = () => import('@/components/Music')
const Admin = () => import('@/components/Admin')
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {path: '/:id?', component: Home,name:'Home'},
        {path: '/Music', component: Music,name:'Music'},
        {path: '/Admin', component: Admin,name:'Admin'}
    ]
})


const store = createStore({
    state() {
        return {
            load_completed: false,
            music_title: '相识相别，同在盛夏',
            is_playing: false,
            is_loading: false,
            progress: 0,
            ischange: false
        }
    },
    mutations: {
        loadComplete(state) {
            state.load_completed = true
        },
        changeTitle(state, music_title) {
            state.music_title = music_title
        },
        updateProgress(state, progress) {
            state.progress = progress
        },
        change(state, ischange) {
            state.ischange = ischange
        },
        updateState(state, is_playing) {
            state.is_playing = is_playing
        },
        setLoading(state, is_loading) {
            state.is_loading = is_loading
        }
    }
})

app.use(ElementPlus, {
    locale: zhCn, size: 'small'
})
app.use(router)
app.use(store)
app.mount('#app')
