<template>
  <NavigationBar></NavigationBar>
  <div class="wrap">
    <router-view v-slot="{ Component }">
      <transition name="component">
        <keep-alive>
          <component :is="Component" :key="$route.name"/>
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<script>


import NavigationBar from "@/components/NavigationBar";
import {ElLoading} from "element-plus"

export default {
  name: 'App',
  components: {
    NavigationBar,
  },
  data() {
    return {
      loading: null
    }
  },

  methods: {
    start_loading() {
      this.loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.9)',
      })
      console.log('lock')

    }
  },
  created() {
  },
  beforeMount() {
    this.start_loading()
  },
  mounted() {
    window.addEventListener("load",this.loading.close)
  },
}
</script>


<style scoped>
.wrap {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: calc(100% - 5px);
  margin: 5px 5px 0 0;
  border-radius: 15px;
  overflow: hidden;
}

</style>

<style>
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  background: linear-gradient(45deg, #1FA2FF, #12D8FA, #A6FFCB) no-repeat fixed;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: calc(100% - 20px);
  width: 100%;
  margin: auto;
  min-width: 1400px;
  min-height: 600px;
  max-width: 1550px;
  display: flex;
}

#beian {
  width: 100%;
  height: 20px;
  min-width: 1400px;
  line-height: 20px;
  font-size: 5px;
  text-align: center;
  overflow: hidden;
}


.component-enter-active {
  transition: all 0.5s linear .55s;
}

.component-leave-active {
  transition: all 0.5s linear;
}

.component-enter-from {
  opacity: 0;
}

.component-leave-to {
  opacity: 0;
}

</style>

