<template>
  <div class="navigation">
    <div class="nav" :style="spread?'width:240px;':''">
      <div class="info">
        <img class="headPortrait"
             src="https://mycloud-1257897713.cos.ap-shanghai.myqcloud.com/images/20220213214031.png"
             alt="头像"/>
        <div class="name">
          <h3>懒人℃</h3>
          <p>贪财好色，一身正气</p>
        </div>
      </div>
      <div class="line"/>
      <div class="menu">
        <div :class="['item',page==='Home'?'item-active':'']" @click="changePage('/')">
          <span class="icon ri-home-2-line"></span>
          <div class="con">主页</div>
        </div>
        <div :class="['item',page==='Music'?'item-active':'']" @click="changePage('/Music')">
          <span class="icon ri-music-2-line"></span>
          <div class="con">我的音乐</div>
        </div>
        <div :class="['item',page==='Admin'?'item-active':'']" @click="changePage('/Admin')">
          <span class="icon ri-terminal-window-fill"></span>
          <div class="con">管理</div>
        </div>
      </div>
      <div class="bottom-box"
           :style="[(page!=='Music') && load_completed?'transform: translateY(0);':'transform: translateY(80px);']">
        <div class="width-button" @click="changeWidth()">
          <span :class="['icon',spread?'ri-arrow-left-s-line':'ri-arrow-right-s-line']"></span>
        </div>
        <div class="music-control-panel">
          <div :class="['music-title',spread?'':'hide']">
            <div ref="musicTitle">{{ musicTitle }}</div>
          </div>
          <div class="control-btn">
            <span :class="['back icon ri-skip-back-fill',spread?'':'hide']" @click="back"></span>
            <div class="state" @click="pause">
              <div :class="['radio icon ri-radio-line',spread?'hide':'']"></div>
              <div
                  :class="['play', 'icon',spread?'':'hide',is_loading?'ri-loader-2-fill icon-rotation':(is_playing? 'ri-pause-fill':'ri-play-fill')]"
                  ></div>
            </div>
            <span :class="['forward icon ri-skip-forward-fill',spread?'':'hide']" @click="next"></span>
          </div>
        </div>
      </div>
      <canvas ref="canvas"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  data() {
    return {
      page: '',
      progress: 0,
      spread: false
    }
  },
  methods: {
    changePage(Page) {
      this.page = Page
      this.$router.push(Page)
    },
    changeWidth() {
      this.spread = !this.spread
    },
    cv_ini() {
      let canvas = this.$refs.canvas
      canvas.width = 240
      canvas.height = canvas.parentElement.clientHeight
      this.context = canvas.getContext('2d')
      this.startTime = Date.now()
    },
    wave(color, amp, height, comp, offset) {
      let t = (Date.now() - this.startTime) / 500 + offset
      this.context.beginPath();
      for (let i = 0; i <= this.$refs.canvas.width; i++) {
        const x = i;
        const y = height + Math.sin(i / 200 + t) * amp;
        this.context[i === 0 ? 'moveTo' : 'lineTo'](x, y);
      }

      this.context.lineTo(this.$refs.canvas.width, this.$refs.canvas.height); // -$.h - Vertically reflection
      this.context.lineTo(0, this.$refs.canvas.height); // -$.h - Vertically reflection
      this.context.closePath();
      this.context.fillStyle = color;

      if (comp) {
        this.context.globalCompositeOperation = comp;
      }

      this.context.fill();
    },
    Animation(count) {
      return new Promise((resolve) => {
        for (let i = 0; i < count * 100; i++) {
          setTimeout(() => {
            if (i < count * 50) {
              this.context.globalAlpha -= 2 / (count * 100)
            } else this.context.globalAlpha += 2 / (count * 100)
            if (i === count * 50) {
              this.progress = isNaN(this.$store.state.progress) ? 0 : this.$store.state.progress
            }
          }, i * 10)
        }
        resolve('ok')
      })
    },
    loop() {
      requestAnimationFrame(this.loop);
      if (this.$store.state.ischange) {
        this.Animation(1).then(value => {
          console.log(value)
        })
        this.$store.commit('change', false)
      }
      this.context.clearRect(0, 0, 240, this.$refs.canvas.height);
      this.wave("#fb000010", 10, this.$refs.canvas.height * (1 - this.$store.state.progress), "screen", 0);
      this.wave("#00ff8e10", 10, this.$refs.canvas.height * (1 - this.$store.state.progress), "screen", Math.PI * 0.66);
      this.wave("#5c940d10", 10, this.$refs.canvas.height * (1 - this.$store.state.progress), "screen", Math.PI * 1.33);
    },
    pause() {
      this.$emitter.emit('pause')
    },
    back() {
      this.$emitter.emit('back')
    },
    next() {
      this.$emitter.emit('next')
    }
  },
  watch: {
    $route() {
      this.page = this.$route.name
    },
    musicTitle() {
      let titleNode = this.$refs.musicTitle
      this.$nextTick(() => {
        if (titleNode.scrollWidth > 190) {
          let duration = titleNode.scrollWidth / 100 + 2
          titleNode.style.animation = 'text-rolling ' + duration + 's linear infinite alternate'
        } else {
          titleNode.style.animation = ''
        }
      })
    }
  },
  computed: {
    load_completed: function () {
      return this.$store.state.load_completed;
    },
    is_playing: function () {
      return this.$store.state.is_playing;
    },
    is_loading: function () {
      return this.$store.state.is_loading;
    },
    musicTitle: function () {
      return this.$store.state.music_title;
    }
  },
  mounted() {
    this.cv_ini()
    window.addEventListener('onresize', () => {
      this.$refs.canvas.height = this.$refs.canvas.parentElement.clientHeight
    })
    this.$emitter.on('show', () => {
      this.loop()

      this.$emitter.off('show')
    })

  }

}
</script>

<style scoped>
.navigation {
  margin: 5px 5px 0 5px;
  display: inline-block;
  height: calc(100% - 5px);
}

.nav {
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 10;
  border-radius: 20px;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  min-height: 595px;
  transition: 0.5s;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav:hover {
  /*width: 240px;*/
}

canvas {
  pointer-events: none;
  position: absolute;
  width: 240px;
  top: 0;
  z-index: -100;
}

.info {
  width: calc(100% - 40px);
  margin-block: 20px;
  height: 60px;
}

.headPortrait {
  height: 48px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.5);
}

.name {
  display: inline-block;
  width: calc(100% - 80px);
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
  text-align: left;
  transition: 0.5s;
}


.name > h3 {
  margin: 15px 0 0;
  font-size: 20px;
}

.name > p {
  margin: 0;
  font-size: 13px;
}

.line {
  width: calc(100% - 40px);
  height: 1px;
  background: rgba(245, 250, 255, 0.3);
  margin-bottom: 5px;
}


.menu {
  width: calc(100% - 40px);
}


.item {
  border-radius: 5px;
  height: 60px;
  transition: 0.5s;
  margin-bottom: 5px;
}

.item-active {
  background: rgba(255, 255, 255, 0.05);
}

.item:hover {
  background: rgba(255, 255, 255, 0.3);
}


.icon {
  display: inline-block;
  vertical-align: top;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  padding-inline: 15px;
}

.con {
  display: inline-block;
  line-height: 60px;
  text-align: center;
  width: calc(100% - 80px);
  white-space: nowrap;
  overflow: hidden;
  transition: 0.5s;
  font-size: 18px;
}

.bottom-box {
  margin-top: auto;
  width: 200px;
  transition: 0.5s;
  z-index: 10;
}

.width-button {
  border-radius: 30px;
  width: 60px;
  transition: 0.5s;
  margin: auto;
}

.width-button:hover {
  background: radial-gradient(closest-side, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

.hide {
  opacity: 0;
}

.music-control-panel {
  height: 80px;
  width: 100%;
  transition: 0.5s;
}

.music-title {
  margin-inline: 25px;
  width: calc(100% - 50px);
  overflow: hidden;
  text-align: center;
  transition: 0.5s;
}

.music-title > div {
  display: inline-block;
  white-space: nowrap;
}


.control-btn {
  width: 100%;
  text-align: center;
}

.back {
  line-height: 60px;
  transition: 0.5s;
  backface-visibility: hidden;
}

.forward {
  line-height: 60px;
  transition: 0.5s;
  backface-visibility: hidden;
}

.state {
  display: inline-block;
  line-height: 60px;
  width: 80px;
}

.play, .radio {
  font-size: 35px;
}

.radio {
  position: absolute;
  transition: 0.5s;
}

.play {
  transition: 0.5s;
}

.icon-rotation {
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>

<style>
@keyframes text-rolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(190px - 100%));
  }
}
</style>